import React from "react";
import { Box, Container, Grid, Link, Image } from "@chakra-ui/react";
import _ from "lodash";
import LogoRedium from "../../assets/images/Footerlogo.svg";
import termsfooter from "../../data/termsfooter.json";
import menu from "../../data/menu.json";
import footericons from "../../data/footericons.json";
import companylogo from "../../assets/images/company.svg";
import useViewportSize from "../../hooks/useViewportSize";

export default function Team() {
  const {width} = useViewportSize();
  const isMobile = width < 720 ? true : false;
  return (
    <>
      <Box as="div" className="bg-wheel-color overflow-hidden sm:mt-0 sm:py-16">
        <Container maxWidth="container.xl">
          <Grid templateColumns={{ base: "1fr", sm: "8fr 4fr" }} gap={2} className={isMobile ? "py-4" : ""}>
            <Box>
              {LogoRedium && (
                <Box as="div" className={isMobile ? "brandlogo mb-4" : "brandlogo mb-10"}>
                  <Image src={LogoRedium} alt="brand Logo" className="" />
                </Box>
              )}
              {menu && (
                <Box as="div" className="w-full flex" gap={3}>
                  {menu.map((item: any, i: number) => (
                    <Link href={item.link} key={i} className={`!text-radium-green`} _hover={{ textDecoration: "underline" }}>
                      {item.label}
                    </Link>
                  ))}
                </Box>
              )}
            </Box>
            <Box as="div" className={`w-full flex items-center ${isMobile ? '' : 'justify-end'}`}>
              {!_.isEmpty(footericons) &&
                footericons.map((icon: any, index: number) => (
                  <Link href={icon.link} key={index} _hover={{ textDecoration: "underline" }}>
                    <Image src={require(`../../assets/images/footerImages/${icon.path}`)} alt="Footer Icon" className="mx-2 h-6 w-6" />
                  </Link>
                ))}
            </Box>
          </Grid>
        </Container>
      </Box>
      <Box as="div" className="bg-radium-green overflow-hidden py-3 footer">
        <Container maxWidth="container.xl">
          <Grid templateColumns="repeat(12, 1fr)">
            {termsfooter.map((item: any, index: number) => (
              <Box key={index} gridColumn={{ base: "span 12", md: `span ${item.size}` }} textAlign="left">
                {item.title}
              </Box>
            ))}
            <Box gridColumn={{ base: "span 12", md: `span 2` }}>
                <img src={companylogo} alt="company logo" />
              </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
