import React, { useState } from "react";
import wellSyncLogo from "../../assets/images/wellSyncLogo.svg";
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";

function Header() {

const LogoIcon = () => <img src={wellSyncLogo} alt="logo" />

  return (
    <Box as="header" className="bg-theme-color-1 z-10" py={4} position="sticky" top="0">
      <Container maxW="container.xl">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link href="/">
            <img src={wellSyncLogo} alt="brand Logo" className="h-12 sm:h-16" />
          </Link>

          <Button
            variant="contained"
            className="bg-theme-green-2 block shadow-none"
            size="lg"
          >
            <Link
              color="radium-green"
              fontWeight="500"
              className="text-radium-green text-capitalize"
            >
              Contact Us
            </Link>
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Header;
