import React from "react";
import { Document, Page, View, Text, StyleSheet, Font, Image } from "@react-pdf/renderer";
import { formattedCurrency } from "../../utils/utils";
import Regular from "../../fonts/regular.ttf";
import SemiBold from "../../fonts/PlusJakartaSans-SemiBold.ttf";
import Logo from "../../assets/images/wellSyncLogo.png";
import _ from "lodash";
import Bgimg from "../../assets/images/bgimg.png";

Font.register({
  family: "Plus Jakarta Sans",
  format: "truetype",
  fonts: [
    { src: Regular, fontWeight: 400 },
    { src: SemiBold, fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  document: {
    width: "100%",
    padding: 10,
    margin: 0,
    textAlign: "left",
    fontFamily: "Plus Jakarta Sans",
    position: "relative",
    height: "100vh",
  },
  pageBackground: {
    position: "absolute",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#ECECEC",
  },
  container: {
    margin: 10,
    padding: 20,
    flexGrow: 1,
    width: "100%",
  },
  section: {
    marginBottom: 10,
  },
  gridRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
  },
  addgap: {
    paddingBottom: 20,
    paddingTop: 20,
  },
  border: {
    borderTop: 1,
    borderStyle: "solid",
    borderColor: "#918E85",
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
    color: "#4F6464",
  },
  value: {
    fontSize: 12,
    fontWeight: 600,
    color: "#002F39",
  },
  title: {
    fontSize: 36,
    fontWeight: 600,
  },
  legend: {
    fontSize: 10,
    color: "#918E85",
  },
  total: {
    fontSize: 30,
    fontWeight: 600,
  },
  image: {
    height: 40,
  },
  pageImg: {
    position: "relative",
    height: "55%",
    width: '55%',
  },
});

const Exportpdf = ({ data }: any) => {
  const { patientsvolume, telemedicinecost, totalValue } = data || {};
  console.log("current values I have", patientsvolume, telemedicinecost, totalValue);
  return (
    <Document style={styles.document}>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageBackground}>
          <Image src={Bgimg} style={styles.pageImg} />
        </View>
        <View style={styles.container}>
          <View style={[styles.gridRow, styles.section]}>
            <View>
              <Text style={styles.legend}>MONTHLY CALCULATOR</Text>
              <Text style={styles.title}>Final Result</Text>
            </View>
            <View>
              <Image src={Logo} style={styles.image} />
            </View>
          </View>
          <View>
            {!_.isEmpty(patientsvolume) &&
              patientsvolume?.map((patient: any, index: number) => (
                <View key={index} style={[styles.gridRow, styles.addgap, styles.border]}>
                  <Text style={styles.label}>{patient.label}:</Text>
                  <Text style={styles.value}>{index === 0 ? patient.value : formattedCurrency(patient.value)}</Text>
                </View>
              ))}
          </View>
          <View>
            {!_.isEmpty(telemedicinecost) &&
              telemedicinecost?.map((cost: any, index: number) => (
                <View key={index} style={[styles.gridRow, styles.addgap, index === patientsvolume.length - 1 ? { ...styles.border, borderBottom: 2 } : styles.border]}>
                  <Text style={styles.label}>{cost.label}:</Text>
                  <Text style={styles.value}>{formattedCurrency(cost.value)}</Text>
                </View>
              ))}
          </View>
          <View style={styles.gridRow}>
            <Text style={styles.label}>Total Amount:</Text>
            <Text style={styles.total}>{formattedCurrency(totalValue)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Exportpdf;
