import React from "react";
import "./App.css";
import Header from "./components/macroComponent/Header";
import Footer from "./components/macroComponent/Footer";
import CalculatorCard from "./components/macroComponent/CalculatorCard";


function App() {

  return (
    <>
      <div className="App">
        <Header />
        <CalculatorCard />
        <Footer />
      </div>
    </>
  );
}

export default App;
