import React, { useEffect, useRef, useState } from "react";
import Calculatordata from "../../data/calculator.json";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Text,
  Card,
  CardBody,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Tooltip,
  FormLabel,
  FormControl,
  Input,
  useToast,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Modal,
  useDisclosure,
} from "@chakra-ui/react";
import Download from "../../assets/images/download.svg";
import Mail from "../../assets/images/mail.svg";
import Exportpdf from "./Exportpdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { formattedCurrency } from "../../utils/utils";
import sendEmail from "../../utils/sendEmail";
import useViewportSize from "../../hooks/useViewportSize";

export default function CalculatorCard() {
  const toast = useToast();
  const sectionRef = useRef(null);
  const { width } = useViewportSize();
  const isMobile = width < 720 ? true : false;
  const { legend, title, patients, cost, subheading, attributes, final, value, button1, button2 } = Calculatordata;
  const [patientsvolume, setPatientsVolume] = useState(patients);
  const [telemedicinecost, setTelemedicineCost] = useState(cost);
  const [salesAttribute, setSalesAttribute] = useState(attributes);
  const [totalValue, setTotalValue] = useState(value);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [pdfData, setPdfData] = useState("PDF Content Here");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState<string>("");

  const handleChangeEmail = (e: any) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleChange = (value: any, item: any) => {
    setPatientsVolume((prev) => [
      ...prev.map((el: any) => {
        if (el.id === item.id) {
          el.value = value;
        }
        return el;
      }),
    ]);
  };

  const handleChangeCost = (value: any, item: any) => {
    setTelemedicineCost((prev: any[]) => [
      ...prev.map((el: any) => {
        if (el.id === item.id) {
          el.value = value;
        }
        return el;
      }),
    ]);
  };
  useEffect(() => {
    if (patientsvolume && telemedicinecost) {
      setSalesAttribute((prev: any) => {
        return [...prev].map((item: any, index: number) => {
          item.value = telemedicinecost[index].value * patientsvolume[index].value;
          return item;
        });
      });
    }
  }, [patientsvolume, telemedicinecost]);

  useEffect(() => {
    if (salesAttribute) {
      const totalSum = salesAttribute.reduce((acc, item: any) => (acc += item.value), 0);
      setTotalValue(totalSum);
    }
  }, [salesAttribute]);
  console.log("width >>", width);

  return (
    <Container maxW="container.xl" ref={sectionRef}>
      <Card mt={8} mb={8} mx="auto" className="!bg-calculator-bg">
        <CardBody className="!p-0">
          <Grid className="px-16 pt-6">
            <GridItem>
              <div className="pb-2">
                <Text mt={1} mb={1} textTransform="uppercase" className="text-legend-color !text-xs">
                  {legend}
                </Text>
                <Text fontSize={isMobile ? "xl" : "2xl"} mt={1} mb={1} fontWeight="600">
                  {title}
                </Text>
              </div>
            </GridItem>
            <GridItem>
              {patientsvolume.map((patient) => (
                <div key={patient.id} className="pb-2">
                  <Text fontSize={isMobile ? "md" : "lg"} mt={1} mb={1} letterSpacing="0.1rem" className="pb-10  !text-theme-green-2">
                    {patient.label}
                  </Text>
                  <Box position="relative">
                    <Slider
                      size={"lg"}
                      onChange={(value) => handleChange(value, patient)}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      defaultValue={0}
                      min={patient.minvalue}
                      max={patient.maxvalue}
                      style={{ transition: "transform 0.3s ease", willChange: "transform" }}
                    >
                      <SliderTrack className="!bg-theme-green-3" h={6} borderRadius="xl">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack className="!bg-theme-green-2" />
                      </SliderTrack>
                      <SliderThumb boxSize={6} style={{ marginLeft: patient.value / patient.maxvalue >= 0.9 ? "-10px" : "15px" }}>
                        <Box position="absolute" top="-25px" left={patient.value / patient.maxvalue >= 0.9 ? "-31px" : "0px"} zIndex="1">
                          <Tooltip hasArrow bg="green" color="white" placement="top" size={"small"} isOpen={showTooltip} children={patient.value > 0 ? `${patient.value}` : <></>} />
                        </Box>
                      </SliderThumb>
                    </Slider>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                      <Box>{patient.minvalue}</Box>
                      <Box>{patient.maxvalue}</Box>
                    </Box>
                  </Box>
                </div>
              ))}
            </GridItem>
          </Grid>
          <Grid>
            <GridItem className="overflow-hidden">
              <hr className="w-4/5 m-auto border-t border-gray-300 h-1 bg-line text-color-green-500 opacity-25 px-16 overflow-hidden" />
            </GridItem>
          </Grid>
          <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"} gap={6} className="px-16">
            {telemedicinecost.map((item, index: number) => (
              <GridItem key={item.id} pt={12} pb={2} className={index < telemedicinecost.length - 1 && !isMobile ? "border-line border-r-2" : ""}>
                <div className={index > 0 && !isMobile ? "pl-10 pr-10" : !isMobile ? "pr-10" : ""}>
                  <Box position="relative">
                    <Slider
                      size={"lg"}
                      defaultValue={0}
                      min={item.minvalue}
                      max={item.maxvalue}
                      onChange={(value) => handleChangeCost(value, item)}
                      style={{ transition: "transform 0.1s ease", willChange: "transform" }}
                    >
                      <SliderTrack className="!bg-theme-green-3" h={6} borderRadius="lg">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack className="!bg-theme-green-2" />
                      </SliderTrack>
                      <SliderThumb boxSize={6} style={{ marginLeft: item.value / item.maxvalue >= 0.9 ? "-10px" : "15px" }}>
                        <Box position="absolute" top="-20px" left={item.value / item.maxvalue >= 0.9 ? "-18px" : "0px"} zIndex="1">
                          <Tooltip hasArrow bg="green" color="white" placement="top" fontWeight="800" isOpen={showTooltip} children={`$${item.value}`} />
                        </Box>
                      </SliderThumb>
                    </Slider>
                    <Text fontSize={isMobile ? "md" : "lg"} mt={1} mb={1} letterSpacing="0.1rem" className="!text-theme-green-2">
                      {item.label}
                    </Text>
                  </Box>
                </div>
              </GridItem>
            ))}
          </Grid>
          <Grid>
            <GridItem className="overflow-hidden">
              <hr className="w-4/5 m-auto border-t border-gray-300 h-1 bg-line text-color-green-500 opacity-25 px-16 overflow-hidden" />
            </GridItem>
          </Grid>
          <div className="title w-full px-16 mt-2">
            <Text fontSize={isMobile ? "xl" : "2xl"} mt={1} mb={1} fontWeight="600" letterSpacing="0.1rem">
              {subheading}
            </Text>
          </div>
          <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"} className="px-8 py-2">
            {salesAttribute.map((item, index) => (
              <GridItem key={index}>
                <div className={`text-center ${isMobile ? "pb-6" : ""}`}>
                  <Text fontSize={isMobile ? "md" : "lg"} mt={1} mb={1} fontWeight="800" letterSpacing="0.1rem">
                    {formattedCurrency(item.value)}
                  </Text>
                  <Text fontSize={isMobile ? "md" : "lg"} mt={1} mb={1} fontWeight="600" letterSpacing="0.1rem">
                    {item.label}
                  </Text>
                </div>
              </GridItem>
            ))}
          </Grid>
          <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} className={`${isMobile ? "px-6" : "px-16"} py-4 bg-wheel-color`}>
            <GridItem>
              <Text fontSize={isMobile ? "md" : "lg"} mt={1} mb={1} fontWeight="600" letterSpacing="0.1rem" className="text-radium-green">
                {final}
              </Text>
              <Text fontSize={isMobile ? "2xl" : "4xl"} mt={1} mb={1} fontWeight="600" letterSpacing="0.1rem" className="text-radium-green">
                {formattedCurrency(totalValue)}/month
              </Text>
            </GridItem>
            <GridItem className="flex items-center justify-end">
              <PDFDownloadLink document={<Exportpdf data={{ patientsvolume, telemedicinecost, totalValue }} filename="wellsyncEstimate.pdf" />}>
                <Button
                  fontSize={isMobile ? "md" : "lg"}
                  size={"lg"}
                  mt={1}
                  mb={1}
                  fontWeight="600"
                  colorScheme="green"
                  letterSpacing="0.1rem"
                  className="mx-2 !min-w-16 !text-radium-green !bg-theme-green-2"
                  variant={"outline"}
                >
                  <img src={Download} alt="download" className="mr-3" />
                  {button1}
                </Button>
              </PDFDownloadLink>
              <Button
                fontSize={isMobile ? "md" : "lg"}
                size={"lg"}
                onClick={onOpen}
                mt={1}
                mb={1}
                fontWeight="600"
                letterSpacing="0.1rem"
                className="mx-2 !min-w-16 !bg-radium-green !text-theme-green-2"
              >
                <img src={Mail} alt="mail" className="mr-3" />
                {button2}
              </Button>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email Estimate</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input value={email} onChange={handleChangeEmail} placeholder="Enter the email" />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <PDFDownloadLink document={<Exportpdf data={{ patientsvolume, telemedicinecost, totalValue }} />} fileName="wellsyncEstimate.pdf">
              {({ blob, url, loading, error }) => {
                const handleSendEmail = async () => {
                  if (blob) {
                    console.log("blob", blob);
                    try {
                      await sendEmail("wellsync@levoleads.com", email, "Welcome", "Your Wellsync estimate is attached below", blob, "wellsyncEstimate.pdf", {
                        "Content-Type": "multipart/form-data",
                      });
                      toast({
                        title: "Success",
                        description: "Email sent successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      onClose();
                    } catch (error) {
                      toast({
                        title: "Error",
                        description: "Email failed to send.",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                      onClose();
                    }
                  }
                };

                return (
                  <Button fontSize={"md"} size={"md"} onClick={handleSendEmail} mt={1} mb={1} fontWeight="600" letterSpacing="0.1rem" className="mx-2 !min-w-16 !bg-radium-green !text-theme-green-2">
                    <img src={Mail} alt="mail" className="mr-3" />
                    {button2}
                  </Button>
                );
              }}
            </PDFDownloadLink>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}
