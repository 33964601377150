import axios from "axios";

const sendEmail = async (from: string, to: string, subject: string, text: string, blob: any, filename: string, header: any) => {
  try {
    const apiKey = "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";
    const baseUrl = "https://sales.wellsync.com/send-email.php";
    const formData = new FormData();
    formData.append("from_email", from);
    formData.append("to_email", to);
    formData.append("subject", subject);
    formData.append("content_value", text);
    formData.append("filename", filename);
    formData.append("attachments", blob);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    };

    const response = await axios.post(baseUrl, formData, { ...headers, ...header });
    return response;
  } catch (error) {
    console.error("Error sending email:", error);
    throw error;
  }
};


export default sendEmail;
